import React from "react";
import s from "./ErrorServerStub.module.css";
import logo from "../../assets/images/logo.png";

const ErrorStub = () => {
  return (
    <div className={s.container}>
      <img src={logo} alt="logo" />
      <p className={s.text}>
        Технические работы на сервере, попробуйте снова позже.
      </p>
    </div>
  );
};

export default ErrorStub;
